import "firebase/auth";

import firebase from "firebase/app";
import { fetchCustomToken } from "../auth";

const firebaseConfig = {
  dev: {
    apiKey: "AIzaSyDw2lDs98MEBKbTzI9CnEM8ZxX2MXZM_wY",
    authDomain: "introist-test.firebaseapp.com",
    databaseURL: "https://introist-test.firebaseio.com",
    projectId: "introist-test",
    storageBucket: "introist-test.appspot.com",
    messagingSenderId: "490240879278",
    appId: "1:490240879278:web:7f689f22b2ffd3039d5b3f",
  },
  test: {
    apiKey: "AIzaSyDw2lDs98MEBKbTzI9CnEM8ZxX2MXZM_wY",
    authDomain: "introist-test.firebaseapp.com",
    databaseURL: "https://introist-test.firebaseio.com",
    projectId: "introist-test",
    storageBucket: "introist-test.appspot.com",
    messagingSenderId: "490240879278",
    appId: "1:490240879278:web:7f689f22b2ffd3039d5b3f",
  },
  production: {
    apiKey: "AIzaSyAfju57hc1NifQLWU_IkFQJE_pm9EgnnsY",
    authDomain: "app.introist.com",
    databaseURL: "https://introist-prod.firebaseio.com",
    projectId: "introist-prod",
    storageBucket: "introist-prod.appspot.com",
    messagingSenderId: "455245726481",
    appId: "1:455245726481:web:6b75984456cc6b964342b0",
  },
};

const app = firebase.initializeApp(
  firebaseConfig[process.env.REACT_APP_ENV || "dev"]
);

export const waitForInitialIDToken = () => {
  return new Promise((resolve) => {
    const unsubscribe = app.auth().onAuthStateChanged(() => {
      resolve(getIDToken());
      unsubscribe();
    });
  });
};

export const getIDToken = () => {
  if (!app.auth().currentUser) return Promise.resolve(undefined);
  return app.auth().currentUser.getIdToken();
};

export const loginWithUrlSignature = (personId, signature) => {
  return fetchCustomToken(personId, signature).then((customToken) =>
    app.auth().signInWithCustomToken(customToken)
  );
};
