import enUS from "./en-us.json";
import fiFI from "./fi-fi.json";
import sv from "./sv.json";

export default {
  "en-US": {
    translation: enUS,
  },
  "fi-FI": {
    translation: fiFI,
  },
  "sv-SE": {
    translation: sv,
  },
  "sv-FI": {
    translation: sv,
  },
};
