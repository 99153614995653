import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import queryString from "query-string";

import { Login } from "./routes/Auth";
import Handbook from "./routes/Handbook";
import SignIn from "./routes/Signin";
import Library from "./routes/Library";
import NotFound from "./routes/NotFound";
import { waitForInitialIDToken } from "./services/firebase";
import { Loader } from "./components";
import { Material } from "./routes/Material";
import { Playground } from "./Playground";

class Identified extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canRender: false,
    };
  }

  componentDidMount() {
    waitForInitialIDToken().then((token) => {
      if (token) this.setState({ canRender: true });
      else window.location.href = "/signin";
    });
  }

  render() {
    if (!this.state.canRender) return <Loader />;
    return this.props.children;
  }
}

function App() {
  const { t } = useTranslation();
  const subDomain =
    window.location.hostname && window.location.hostname.split(".")[0];

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route
            exact
            path="/signin"
            render={() => <Login teamDomain={subDomain} />}
          />
          <Route
            exact
            path="/s/:personId/:targetId"
            render={(props) => (
              <SignIn
                personId={props.match.params.personId}
                targetId={props.match.params.targetId}
                signature={queryString.parse(props.location.search).s}
              />
            )}
          />

          <Route
            exact
            path="/handbook/:handbookId"
            render={(props) => (
              <Identified>
                <Handbook
                  handbookId={props.match.params.handbookId}
                  onError={() => {
                    toast.error(t("error"));
                  }}
                />
              </Identified>
            )}
          />

          <Route
            exact
            path="/library"
            render={() => <Library teamName={subDomain} />}
          />

          <Route exact path="/playground" render={() => <Playground />} />

          <Route
            exact
            path="/:materialId"
            render={(props) => (
              <Identified>
                <Material materialId={props.match.params.materialId} />
              </Identified>
            )}
          />

          <Route component={NotFound} />
        </Switch>
      </Router>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
