import { apiUrl } from "../config";
import { getIDToken } from "./firebase";

class Api {

  loadMaterial(materialId) {
    return this._getJson(`/v1/read/material/${materialId}`, true);
  }

  getDownloadUrl(fileKey) {
    return this._getJson(`/v1/files?key=${fileKey}`, true);
  }

  loadHandbook(handbookId) {
    return this._getJson(`/v1/read/handbook/${handbookId}`, true);
  }

  confirmMaterial(materialId) {
    return this._sendRequest(
      `/v1/read/material/${materialId}/confirm`,
      {},
      "post",
      true
    );
  }

  getContentType(url) {
    return fetch(url).then(response => {
     return response.headers.get('content-type');
    })
   } 

  loadGoogleFonts() {
    if (this.cachedFonts) return Promise.resolve(this.cachedFonts);

    return fetch(
      "https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyAQRJsYydOdFoAwL_jHsPfgcqQjzj0Vftg"
    )
      .then(this._jsonResponse)
      .then((fonts) => {
        return fonts;
      })
      .then((fonts) => {
        this.cachedFonts = fonts.items;
        return this.cachedFonts;
      });
  }


  async _sendRequest(path, body, method = "POST", auth = false) {
    const token = await getIDToken();
    const headers = {
      "Content-Type": "application/json",
    };

    if (auth) {
      headers["authorization"] = `Bearer ${token}`;
    }

    return fetch(`${apiUrl}${path}`, {
      method,
      headers,
      body: body ? JSON.stringify(body) : undefined,
    }).then(this._jsonResponse);
  }

  _getJson(path, auth = false) {
    return this._sendRequest(path, null, "GET", auth);
  }

  _jsonResponse(res) {
    if (res.status === 200) return res.json();
    else
      return res
        .text()
        .then((error) => Promise.reject({ status: res.status, error }));
  }
}



export default new Api();
