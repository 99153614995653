import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import HandbookListCard from "../components/HandbookListCard";
import Header from "../components/Header";
import Modal from "../components/molecules/Modal";
import PrimaryButton from "../components/PrimaryButton";
import Api from "../services/api";
import styles from "./Library.module.css";

const hasSeenOnboarding = () => {
  const seen = localStorage.getItem("zapter.v1.visited");
  return !!seen;
};

const setSeenOnboarding = () => {
  localStorage.setItem("zapter.v1.visited", "true");
};

export default () => {
  const { t } = useTranslation();
  const [assignments, setAssignments] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    async function load() {
      try {
        const assignments = await Api.loadAssignments();
        setAssignments(assignments);
      } catch (e) {
        toast.error(t("error"));
      }
    }

    load();
    setModalOpen(!hasSeenOnboarding());
  }, [t]);

  const setSeen = () => {
    setModalOpen(false);
    setSeenOnboarding();
  };

  return (
    <div>
      <Helmet>
        <title>{t("title")}</title>
        <meta name="description" content="Your onboarding library" />
      </Helmet>
      <Header />
      <div className={styles.LibraryRoot}>
        <h2>{t("library.title")}</h2>
        <p>{t("library.subtitle")}</p>

        <p>{t("library.disclaimer")}</p>

        <div className={styles.handbookSection}>
          <h5>{t("library.handbooks.title")}</h5>

          <div className={styles.handbookContainer}>
            {assignments.map((assignment) => (
              <div className={styles.handbookItem} key={assignment.id}>
                <HandbookListCard
                  name={assignment.name}
                  linkUrl={assignment.fullUrl}
                  active={assignment.confirmation_required}
                  completed={!!assignment.confirmed_at}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Modal className={styles.Onboarding} isOpen={modalOpen}>
        <h2 className={styles.OnboardingTitle}>
          {t("library.onboarding.title")}
        </h2>
        <p className={styles.OnboardingDescription}>
          {t("library.onboarding.description")}
        </p>
        <PrimaryButton
          title={t("library.onboarding.button")}
          onClick={setSeen}
        />
      </Modal>
    </div>
  );
};
