import "./Handbook.css";

import React, { Component } from "react";
import InlineCss from "react-inline-css";
import Scroll from "react-scroll";

import CloseSmall from "../assets/close-small.svg";
import MenuIcon from "../assets/menu-icon.svg";
import { Loader, renderBlock, TableOfContent, ToolbarButton } from "../components";
import { Themes } from "../config";
import Api from "../services/api";
import { getTeamFromUrl } from "../services/Helpers";
import NotFound from "./NotFound";

const DESKTOP_ROOT = "DesktopHandbookContent";
const MOBILE_ROOT = "MobileHandbook";

const INITIAL_SCROLL_TIMEOUT = 700;

export default class Handbook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tocOpen: false,
    };
  }

  async componentDidMount() {
    Scroll.Events.scrollEvent.register("begin", () =>
      this.setState({ scrolling: true })
    );
    Scroll.Events.scrollEvent.register("end", () =>
      this.setState({ scrolling: false })
    );

    await this.loadHandbook();

    this.scrollToAnchor();
  }

  async loadHandbook() {
    const { handbookId } = this.props;
    try {
      const handbook = await Api.loadHandbook(handbookId);
      
      if (!handbook.theme) handbook.theme = Themes.default;
      document.title = handbook.name;
      this.setState({
        handbook: handbook.data,
        theme: handbook.theme,
        name: handbook.name,
        nextChapter: handbook.nextChapter,
        shouldConfirm: handbook.confirmation_required,
        isConfirmed: handbook.confirmed_at,
      });
      Themes.loadFonts(handbook.theme).then(() =>
        this.setState({ fonts: true })
      );
    } catch (err) {
      this.props.onLoadFail()
      if (this.props.onError) this.props.onError();
      this.setState({ error: true });
    }
  }

  scrollToAnchor() {
    setTimeout(() => {
      const anchor = window.location.hash
        ? window.location.hash.substring(1)
        : null;

      if (anchor) {
        this.scrollTo(this.isDesktop() ? DESKTOP_ROOT : MOBILE_ROOT, anchor);
      }
    }, INITIAL_SCROLL_TIMEOUT);
  }

  componentWillUnmount() {
    Scroll.Events.scrollEvent.remove("begin");
    Scroll.Events.scrollEvent.remove("end");
  }

  blockReached(block) {
    if (!this.state.scrolling) this.setState({ waypoint: block.id });
  }

  scrollTo(scrollContainer, blockId) {
    const options = {
      duration: 1000,
      delay: 0,
      smooth: true,
    };

    if (scrollContainer) options.containerId = scrollContainer;

    Scroll.scroller.scrollTo(blockId, options);
    this.setState({ waypoint: blockId });
  }

  chapterClicked(scrollContainer, chapterId) {
    this.scrollTo(scrollContainer, chapterId);
    this.setState({ openTocChapter: chapterId });
  }

  onConfirmEnroll() {
    Api.confirmEnroll(this.props.enrollId)
      .then((res) => this.setState({ isConfirmed: res.confirmed_at }))
      .catch((e) => {
        console.log(e);
        if (this.props.onError) this.props.onError();
      });
  }

  render() {
    if (this.state.error) return <NotFound />;
    else if (this.state.handbook && this.state.fonts)
      return this.renderHandbook();
    else return this.renderLoading();
  }

  isDesktop() {
    return matchMedia("only screen and (min-width: 1025px)").matches;
  }

  renderHandbook() {
    const blocks = this.state.handbook.map((block) => {
      return renderBlock(
        block,
        this.blockReached.bind(this, block),
        null,
        null,
        null,
        null,
        null,
        null,
        this.state.waypoint,
        this.props.enrollId
      );
    });

    const stylesheet = Themes.stylesheetFor(this.state.theme);

    return this.isDesktop()
      ? this.renderDesktop(blocks, stylesheet)
      : this.renderMobile(blocks, stylesheet);
  }

  renderDesktop(blocks, stylesheet) {
    return (
      <InlineCss className="Handbook Desktop" stylesheet={stylesheet}>
        {this.state.tocOpen && (
          <div>
            {this.renderToc(
              DESKTOP_ROOT,
              false,
              this.scrollTo.bind(this, DESKTOP_ROOT)
            )}
          </div>
        )}
        <div id={DESKTOP_ROOT}>
          {blocks}
        </div>
        {this.renderTocButton()}
      </InlineCss>
    );
  }

  renderMobile(blocks, stylesheet) {
    const styles = {};
    if (this.state.tocOpen) styles.overflow = "hidden";

    return (
      <InlineCss
        namespace={MOBILE_ROOT}
        className="MobileHandbook Handbook"
        style={styles}
        stylesheet={stylesheet}
      >
        {blocks}
        {this.state.tocOpen &&
          this.renderToc(MOBILE_ROOT, true, (blockId) => {
            this.scrollTo(MOBILE_ROOT, blockId);
            this.setState({ tocOpen: false });
          })}
        {this.renderTocButton()}
      </InlineCss>
    );
  }

  renderToc(containerId, mobile, blockClicked) {
    return (
      <TableOfContent
        handbook={this.state.handbook}
        name={this.state.name}
        waypoint={this.state.waypoint}
        openChapter={this.state.openTocChapter}
        customisation={getTeamFromUrl()}
        mobile={mobile}
        blockClicked={blockClicked}
        chapterClicked={this.chapterClicked.bind(this, containerId)}
      />
    );
  }

  renderLoading() {
    return <Loader />;
  }

  renderTocButton() {
    return (
      <TocButton
        tocOpen={this.state.tocOpen}
        tocButtonOnClick={() =>
          this.setState({
            tocOpen: !this.state.tocOpen,
          })
        }
      />
    );
  }
}

const TocButton = ({ tocOpen, tocButtonOnClick }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "16px",
        left: "16px",
        display: "flex",
      }}
    >
      <ToolbarButton
        icon={tocOpen ? CloseSmall : MenuIcon}
        onClick={tocButtonOnClick}
      />
    </div>
  );
};
