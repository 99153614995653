import "./Popupmods.css";

import cx from "classnames";
import React, { useEffect, useState } from "react";
import { Modal, Popup } from "semantic-ui-react";

import { ReactComponent as Check } from "../../assets/Check.svg";
import { ReactComponent as Confirmed } from "../../assets/Confirmed_Stamp.svg";
import { ReactComponent as Cross } from "../../assets/Cross.svg";
import { ReactComponent as ArrowRight } from "../../assets/Front.svg";
import { InlineLoader } from "../../components/Loader";
import Api from "../../services/api";
import styles from "./ReadConfirmationWidget.module.css";


const PopupContent = ({ onClose }) => {

  return (
    <div className={styles.PopupContent}>
      <div className={styles.Header}>
        <h4>Read confirmation</h4>
        <Cross onClick={onClose}/>
      </div>
      <div>Remember to let us know when you’ve read through this material.</div>
    </div>
  );
}

export const ReadConfirmationWidget = ({ material }) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [confirmedModalOpen, setConfirmedModalOpen] = useState(false);
  const contextRef = React.useRef()
  const popupContentRef = React.useRef()

  const isDesktop = () => {
    return matchMedia("only screen and (min-width: 1025px)").matches;
  }

  useEffect(() => {
    setIsConfirmed(material.is_confirmed);
    setPopupOpen(!material.is_confirmed);
  }, [material]);

  const onConfirm = () => {
    setConfirmationLoading(true);
    setPopupOpen(false);
    setConfirmedModalOpen(true);
    Api.confirmMaterial(material.id)
      .then(() => {
        setConfirmationLoading(false);
        setIsConfirmed(true);
      })
      .catch(console.log);
  };

  const elementWidth = contextRef && contextRef.current ? contextRef.current.offsetWidth : 0;
  const popupPosition = isDesktop() ? 'top right' : 'top right';
  const popupOffset = isDesktop() ? `-${elementWidth - 32}, 32` : `32, 32`;
  const highlightOffset = isDesktop() ? `0, -${elementWidth + 27}` : `0, -${elementWidth + 27}`;
  const highlightPosition = isDesktop() ? 'left center' : 'right center';

  return (
    <>
      <Popup offset={popupOffset} content={<div ref={popupContentRef}><PopupContent onClose={() => setPopupOpen(false)} /></div>} open={popupOpen} context={contextRef} position={ popupPosition}/>
      <Popup offset={highlightOffset} className='highlight' content={<div />} open={popupOpen} context={contextRef} position={highlightPosition}/>
      <div
        ref={contextRef}
      className={cx(styles.Bubble, {
        [styles.Confirmed]: isConfirmed,
        [styles.Mobile]: !isDesktop(),
        [styles.Highlighted]: popupOpen
      })}
      onClick={!isConfirmed && onConfirm}
    >
        <div className={styles.BubbleText}>{isConfirmed ? 'Confirmed' : 'Confirm'}</div> {isConfirmed ? <Check /> : <ArrowRight />}
      </div>
      <Modal size="small" closeIcon={<div className='close icon'><Cross /></div>} onClose={() => setConfirmedModalOpen(false)} open={confirmedModalOpen}>
        <Modal.Content>
          <div className={styles.ConfirmedModal}>
            {confirmationLoading ? <InlineLoader /> : <Confirmed />}
            <h3>{confirmationLoading ? 'Please wait while we are updating your progress!' : 'Reading confirmation saved succesfully.'}</h3>
            <div>{confirmationLoading ? ' ' : 'Thanks for letting us know you’ve progressed with your onboarding materials!'}</div>
          </div>
          
        </Modal.Content>
      </Modal>
      </>
  );
};
