import React from "react";

import styles from "./PrimaryButton.module.css";

export default ({ title, onClick }) => {
  return (
    <button className={styles.primaryButton} onClick={onClick}>
      {title}
    </button>
  );
};
