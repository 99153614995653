import { apiUrl } from "../config";

export const initiateSignIn = (teamDomain, email) => {
  return fetch(`${apiUrl}/v1/a/initiate`, {
    method: "post",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({ teamDomain, email }),
  }).then((res) => {
    if (res.status === 200) return Promise.resolve();
    else return Promise.reject();
  });
};

export const fetchCustomToken = (personId, signature) => {
  return fetch(`${apiUrl}/v1/a/${personId}?signed=${signature}`, {
    method: "post",
  }).then((res) => {
    if (res.status === 200) return res.json().then((body) => body.jwt);
    else return Promise.reject();
  });
};
