import "./TableOfContent.css";

import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion } from "semantic-ui-react";

import { blocksForChapter, BlockTypes } from "./";

export const TableOfContent = ({
  name,
  handbook,
  waypoint,
  blockClicked,
  chapterClicked,
  mobile,
  disableDesc,
}) => {
  const { t } = useTranslation();
  let openChapterIndex = 0;

  const tocItems = handbook
    .filter((block) => block.type === BlockTypes.chapter)
    .map((chapter, index) => {
      const blocks = blocksForChapter(handbook, chapter.id);

      const isActiveChatBlock = (blockIndex) => {
        const block =
          blocks.length > blockIndex ? blocks[blockIndex] : undefined;
        return block && block.type === BlockTypes.chat && waypoint === block.id;
      };

      if (chapter.id === waypoint || isActiveChatBlock(0))
        openChapterIndex = index;

      const blockItems = blocks
        .filter((block) => block.type !== BlockTypes.chat)
        .map((block, blockIndex) => {
          let isActiveBlock = false;

          if (block.id === waypoint || isActiveChatBlock(blockIndex + 1)) {
            openChapterIndex = index;
            isActiveBlock = true;
          }

          return (
            <button
              key={`block-${block.id}`}
              onClick={() => blockClicked(block.id)}
              className="BlockItem"
            >
              <span
                style={{
                  minWidth: "40px",
                }}
                className={isActiveBlock ? 'ActiveBlockItem' : 'InActiveBlockItem'}
              >
                {index + 1}.{blockIndex + 1}.
              </span>
              <span
                className={isActiveBlock ? 'ActiveBlockItem' : 'InActiveBlockItem'}
              >
                {block.title || t("toc.untitledBlock")}
              </span>
            </button>
          );
        });

      if (blockItems.length === 0) {
        blockItems.push(
          <span key={`${chapter.id}-noblocks`} className="BlockItem">
            {t("toc.noBlocks")}
          </span>
        );
      }

      let chapterScheduling = null;

      const isActiveChapter = waypoint === chapter.id || isActiveChatBlock(0);

      const title = (
        <div className="ChapterTocItem" key={chapter.id}>
          <div
            className="ChapterNameContainer"
            onClick={() => chapterClicked(chapter.id)}
          >
            <span>
              {t("toc.chapter")} {index + 1}
            </span>
            <h3 style={{ color: isActiveChapter ? "var(--theme-colors-secondary)" : "var(--theme-colors-primary)" }}>
              {chapter.name || t("toc.untitledChapter")}
            </h3>
          </div>
          {chapterScheduling}
        </div>
      );

      const content = (
        <Accordion.Content className="ChapterTocContent">
          {blockItems}
        </Accordion.Content>
      );

      return { key: chapter.id, title, content };
    });
  const tocContent = (
    <div className="TableOfContent">
      <div className="TocHeader">
        <div className="TocHandbookName">
          <span className="Name">{name}</span>
        </div>
      </div>
      <Accordion
        className="Toc"
        panels={tocItems}
        activeIndex={openChapterIndex}
        onTitleClick={() => {}}
      />
    </div>
  );

  return (
    <div className={mobile ? "TocRoot MobileToc" : "TocRoot TocContainer"}>
      {!disableDesc && (
        <span className="TocTitle">{t("toc.tableOfContent")}</span>
      )}
      {tocContent}
    </div>
  );
};
