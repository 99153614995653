import {
  BlockBase,
  ChapterBlock,
  ContentBlock,
  EmbedBlock,
  ScheduleBlock,
  VideoBlock,
} from "@zapterlabs/introist-handbook-components";
import React from "react";

export const BlockTypes = {
  chapter: "chapter",
  content: "content",
  video: "video",
  embed: "embed",
  chat: "chat",
  schedule: "schedule"
};

export const blocksForChapter = (handbook, chapterId) => {
  let currentChapter = null;
  return handbook.filter((block) => {
    if (block.type === BlockTypes.chapter) {
      currentChapter = block.id;
      return false;
    }
    return currentChapter === chapterId;
  });
};

export const renderBlock = (
  block,
  onEnter,
  onBlockUpdated,
  onToggleToolbar,
  onMoveUp,
  onMoveDown,
  onDelete,
  onAdd
) => {
  let blockContent;

  if (block.type === BlockTypes.chapter) {
    blockContent = <ChapterBlock key={block.id} block={block} />;
  } else if (block.type === BlockTypes.content) {
    blockContent = <ContentBlock ref={block.id} key={block.id} block={block} />;
  } else if (block.type === BlockTypes.video) {
    blockContent = <VideoBlock ref={block.id} key={block.id} url={block.url} block={block}/>;
  } else if (block.type === BlockTypes.embed) {
    blockContent = (
      <EmbedBlock ref={block.id} key={block.id} url={block.url} block={block} />
    );
  } else if (block.type === BlockTypes.schedule ) {
    blockContent = <ScheduleBlock ref={block.id} key={block.id} block={block} />
  }

  const styles = buildBlockStyles(block);
  const overlay = buildBlockOverlay(block);

  return (
    <BlockBase
      key={`block-base-${block.id}`}
      id={blockHash(block)}
      onEnter={onEnter}
      name={block.id}
      style={styles}
      onMoveUp={onMoveUp}
      onMoveDown={onMoveDown}
      onToggleToolbar={onToggleToolbar}
      onDelete={onDelete}
      onAdd={onAdd}
      onUpdateBlock={onBlockUpdated}
      block={block}
    >
      {overlay}
      {blockContent}
    </BlockBase>
  );
};

const buildBlockStyles = (block) => {
  let styles = {
    color: block.textColor,
  };

  if (block.backgroundImage) {
    const url = block.backgroundImage.url;

    styles.background = `url(${url})`;
    styles.backgroundRepeat = "none";
    styles.backgroundPosition = "center";
    styles.backgroundRepeat = "no-repeat";
    styles.backgroundSize = "cover";
    styles.zIndex = 0;
  } else {
    styles.background = block.background;
  }

  if (block.type !== BlockTypes.chat) {
    styles.minHeight = "100%";
  }

  return styles;
};

const buildBlockOverlay = (block) => {
  if (block.backgroundImage && block.backgroundImage.overlay) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: block.background,
          opacity: 0.8,
          zIndex: -1,
        }}
      />
    );
  } else return null;
};

const blockHash = (block) => {
  const hash = "z" + block.id.replace(/-/g, ""); //hash should start with a letter
  return hash;
};
