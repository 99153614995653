import "../i18n";

import React, { useState } from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import Modal from "../components/molecules/Modal";
import PrimaryButton from "../components/PrimaryButton";
import { initiateSignIn } from "../services/auth";
import styles from "./Auth.module.css";

export const Login = ({ teamDomain }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const onSubmit = () => {
    initiateSignIn(teamDomain, email)
      .then(() => setSuccess(true))
      .catch(() => setError(true))
      .finally(() => setEmail(""));
  };

  return (
    <div className={styles.FullscreenWrapper}>
      <Helmet>
        <title>{t("title")}</title>
      </Helmet>
      <Modal isOpen={true}>
        <div className={styles.AuthRoot}>
          <h2>{t("auth.welcome")}</h2>
          <p>{t("auth.subtitle")}</p>

          <p>{t("auth.instructions")}</p>

          <input
            type="text"
            placeholder={t("auth.email")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <div className={styles.submit}>
            <PrimaryButton onClick={onSubmit} title={t("auth.button")} />
          </div>

          <div className={styles.info}>
            {success && (
              <div className={styles.success}>{t("auth.successMessage")}</div>
            )}
            {error && (
              <div className={styles.error}>{t("auth.errorMessage")}</div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
