import React from "react";
import { useTranslation } from "react-i18next";

import { ReactComponent as Check } from "../assets/check-small.svg";
import { ReactComponent as ChevronRight } from "../assets/chevron-right.svg";
import styles from "./HandbookListCard.module.css";

export default ({ name, active, completed, linkUrl }) => {
  const { t } = useTranslation();

  let requiredText = null;

  const additionalClasses = completed ? [styles.success] : [];

  if (active) {
    requiredText = !completed ? (
      <span className={styles.minorLabel}>
        {t("library.handbooks.card.unconfirmed")}
      </span>
    ) : (
      <span className={[styles.minorLabel, ...additionalClasses].join(" ")}>
        {t("library.handbooks.card.confirmed")} <Check />
      </span>
    );
  }

  return (
    <a
      href={linkUrl}
      rel="noopener noreferrer"
      key={name}
      className={[styles.card, ...additionalClasses].join(" ")}
    >
      <div className={styles.titleContainer}>
        <span className={styles.title}>{name}</span>
      </div>
      <div className={styles.rightContainer}>{requiredText}</div>
      <div className={styles.svgContainer}>
        <ChevronRight />
      </div>
    </a>
  );
};
