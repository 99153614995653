import React, { Component } from "react";
import { loginWithUrlSignature } from "../services/firebase";
import { withRouter } from "react-router";
import { Loader } from "../components";

class SignIn extends Component {
  componentDidMount() {
    loginWithUrlSignature(this.props.personId, this.props.signature)
      .then(() => {
        this.props.history.push(`/${this.props.targetId}`);
      })
      .catch((err) => {
        console.log(err);
        this.props.history.push("/signin");
      });
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader />
      </div>
    );
  }
}

export default withRouter(SignIn);
