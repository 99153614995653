import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router";

import { Loader } from "../components";
import { ReadConfirmationWidget } from "../containers/ReadConfirmationWidget";
import Api from "../services/api";
import Handbook from "./Handbook";

const MaterialInt = ({ materialId }) => {
  const [material, setMaterial] = useState();
  const [handbookLoadFailed, setLoadFailed] = useState(false);

  const loadMaterial = useCallback(async (materialId) => {
    const material = await Api.loadMaterial(materialId);

    if (material.material_type === "handbook") {
      setMaterial(material);
    } else if (material.material_type === "file") {
      const { url } = await Api.getDownloadUrl(material.data);
      const contentType = await Api.getContentType(url);
      material.contentType = contentType;
      material.url = url;
      setMaterial(material);
    } else if (material.material_type === "link") {
      window.location.href = material.data;
    }
  }, []);

  useEffect(() => {
    loadMaterial(materialId);
  }, [loadMaterial, materialId]);

  if (!material) return <Loader />;

  const getMaterialContent = () => {
    if (material.material_type === "handbook") {
      return <Handbook handbookId={material.data} onLoadFail={() => setLoadFailed(true)}/>;
    }
    
    if (material.material_type === "file") {
      if (material.contentType && material.contentType.startsWith('video')) {
        return <div style={{height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <video controls style={{maxWidth: '100%', maxHeight: '100%'}}>
          <source src={material.url} />
          Your browser does not support the video tag.
        </video>
        </div>
      }
      return (
        <iframe
          style={{ width: "100%", height: "100vh", border: "none" }}
          title={material.title}
          src={material.url}
        />
      );
    }
  };
  return (
    <>
      {getMaterialContent()}
      {material && material.confirmation_required && !handbookLoadFailed && <ReadConfirmationWidget material={material} />}
    </>
  );
};

export const Material = withRouter(MaterialInt);
