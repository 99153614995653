import React from "react";
import styles from "./Header.module.css";

import { ReactComponent as Logo } from "../assets/introist-blue.svg";

export default () => {
  return (
    <div className={styles.header}>
      <Logo />
    </div>
  );
};
