import React from "react";

import { ReadConfirmationWidget } from "./containers/ReadConfirmationWidget";

export const Playground = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReadConfirmationWidget
        material={{ title: "Test handbook", is_confirmed: true }}
      />
    </div>
  );
};
