import React from 'react'
import ReactBodyMovin from 'react-bodymovin'
import loaderAnimation from '../assets/loading-indicator-v2.json'

const loaderBM = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData: loaderAnimation
}

export const Loader = () => (
  <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1}}>
    <InlineLoader />
  </div>
)

export const InlineLoader = () => (
  <div style={{width: '120px', height: '120px'}}>
    <ReactBodyMovin options={loaderBM}/>
  </div>
)