import React from 'react'
import { useTranslation } from 'react-i18next';

import NotFoundIcon from '../assets/not-found.png'

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <span style={{ fontStyle: 'italic', marginTop: '64px' }}>{t('notfound.title')}</span>
      <h2 style={{ marginTop: '16px' }}>{t('notfound.subtitle')}</h2>
      <img src={NotFoundIcon} alt="Not found" style={{ width: '60%' }} />

    </div>
  )
}

export default NotFound