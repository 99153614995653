import moment from 'moment'


export const timestampToLongDate = timestamp => {
  if (!timestamp) return ''
  else {
    const time = moment.unix(timestamp)
    let format = null
    if (time.isSame(moment(), 'year')) format = 'MMM D'
    else format = 'MMM D, Y'

    return time.format(format)
  }
}

export const timestampToDateTime = timestamp => {
  if (!timestamp) return ''
  else {
    const time = moment.unix(timestamp)
    let format = null
    if (time.isSame(moment(), 'year')) format = 'MMM D, HH:mm'
    else format = 'MMM D, Y, HH:mm'

    return time.format(format)
  }
}

export const validators = {
  isEmpty: value => !value || value === null || value === '',
  isValidEmail: value => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(value)
  }
}

export const teamNameToDomain = teamName => {
  return teamName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
}


export const getTeamFromUrl = () => {
  const host = window.location.hostname
  return host.split(".")[0]
}

export const isDesktop = matchMedia('only screen and (min-width: 768px)').matches

export const roundTo = (value, precision) => {
  const factor = Math.pow(10, precision)
  const tempNumber = value * factor
  const roundedTempNumber = Math.round(tempNumber)
  return roundedTempNumber / factor
}