import WebFont from "webfontloader";

import api from "./services/api";

const env = process.env.REACT_APP_ENV || "dev";

export const apiUrl = {
  production: " https://handbooks.api.gcp.introist.com",
  test: "https://handbooks.test.introist.com",
  dev: "http://localhost:3001",
}[env];

const fonts = [
  "Ubuntu",
  "Raleway",
  "Open Sans",
  "Montserrat",
  "Muli",
  "Roboto",
  "Lato",
  "Oswald",
  "Source Sans Pro",
  "PT Sans",
  "Playfair Display",
  "Indie Flower",
  "Oxygen",
  "Quicksand",
  "Exo 2",
  "Lora",
  "Noto Sans",
  "Poppins",
  "Bungee",
  "Lobster",
  "Dosis",
  "Anton",
  "Bitter",
  "Merriweather",
  "Inter",
  "DM Sans",
  "DM Serif Display"
];

const stylishSansSerif = {
  name: "Stylish Sans Serif",
  typography: [
    {
      name: "Chapter",
      font: "Montserrat",
      size: 72,
    },
    {
      name: "Heading 1",
      font: "Montserrat",
      size: 36,
    },
    {
      name: "Heading 2",
      font: "Open Sans",
      size: 24,
    },
    {
      name: "Normal",
      font: "Open Sans",
      size: 16,
    },
  ],
};

const coolAndCondensed = {
  name: "COOL'N'CONDENSED",
  typography: [
    {
      name: "Chapter",
      font: "Oswald",
      size: 100,
    },
    {
      name: "Heading 1",
      font: "Oswald",
      size: 42,
    },
    {
      name: "Heading 2",
      font: "Lato",
      size: 24,
    },
    {
      name: "Normal",
      font: "Lato",
      size: 16,
    },
  ],
};

const classicSerif = {
  name: "Classic Serif",
  typography: [
    {
      name: "Chapter",
      font: "Playfair Display",
      size: 72,
    },
    {
      name: "Heading 1",
      font: "Playfair Display",
      size: 42,
    },
    {
      name: "Heading 2",
      font: "Raleway",
      size: 24,
    },
    {
      name: "Normal",
      font: "Raleway",
      size: 20,
    },
  ],
};

const REM_HEADING_BASE = 20;
const REM_BODY_BASE = 14;

export const Themes = {
  default: stylishSansSerif,
  premade: [stylishSansSerif, coolAndCondensed, classicSerif],
  loadFontSelection: () => {
    return api.loadGoogleFonts().then((loaded) => {
      const filtered = loaded.filter(
        (font) => fonts.indexOf(font.family) !== -1
      );
      filtered.sort((a, b) => a.family.localeCompare(b.family));

      WebFont.load({
        google: {
          families: filtered.map((font) => `${font.family}:regular,700`),
        },
      });

      return filtered;
    });
  },
  loadFonts: (theme) => {
    if (!theme) theme = Themes.default;

    const families = theme.typography.map((_) => `${_.font}:regular,700`);

    return new Promise((resolve) => {
      WebFont.load({
        active: resolve,
        google: { families },
      });
    });
  },
  stylesheetFor: (theme) => {
    const colors = theme.colors;
    const typo = theme.typography;
    const chapter = typo.find((_) => _.name === "Chapter");
    const heading1 = typo.find((_) => _.name === "Heading 1");
    const heading2 = typo.find((_) => _.name === "Heading 2");
    const normal = typo.find((_) => _.name === "Normal");

    const primary = colors && colors.primary ? colors.primary : 'rgb(31, 141, 206)';
    const secondary = colors && colors.secondary ? colors.secondary : 'rgb(255, 156, 71)';
    const typography = colors && colors.typography ? colors.typography : 'rgb(153, 153, 153)';
    const background = colors && colors.background ? colors.background : '#282828';

    const stylesheet = `
    :root {
      --theme-colors-primary: ${primary};
      --theme-colors-secondary: ${secondary};
      --theme-colors-typography: ${typography};
      --theme-colors-background: ${background};
    }
     h1, .h1, .h1>input {
      font-family: ${chapter.font} !important;
      font-size: ${chapter.size / REM_HEADING_BASE}rem  !important;
     }
     
     h2, .h2, .h2>input {
      font-family: ${heading1.font}  !important;
      font-size: ${heading1.size / REM_HEADING_BASE}rem  !important;
     }
     
     h3, .h3 {
      font-family: ${heading2.font}  !important;
      font-size: ${heading2.size / REM_HEADING_BASE}rem  !important;
     }
     
     p {
      font-family: ${normal.font}  !important;
      font-size: ${normal.size / REM_BODY_BASE}rem  !important;
     
     }
     
     li {
      font-family: ${normal.font}  !important;
      font-size: ${normal.size / REM_BODY_BASE}rem  !important;
     }
     
     strong {
      font-weight: 700;
     }  
    `;

    return stylesheet;
  },
};
