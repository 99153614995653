import React from "react";
import { Modal } from "semantic-ui-react";

import { ReactComponent as Logo } from "../../assets/introist-blue.svg";
import styles from "./Modal.module.css";

export default ({ isOpen, children, className }) => (
  <Modal className={className} size="small" open={isOpen}>
    <Modal.Content className={styles.Content}>
      <Logo style={{ width: "50%", margin: "0 auto" }} />
      {children}
    </Modal.Content>
  </Modal>
);
