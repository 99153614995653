import "./Button.css";

import React from "react";
import { Loader } from "semantic-ui-react";

const preventAndFire = (handler) => (e) => {
  e.preventDefault();
  handler(e);
};

const fireIfNotLoading = (loading, onClick) =>
  loading ? (e) => e.preventDefault() : preventAndFire(onClick);

export const PrimaryButton = ({
  id,
  onClick,
  style,
  children,
  loading,
  color,
  disabled,
}) => {
  const clickHandler = disabled
    ? (e) => e.preventDefault()
    : fireIfNotLoading(loading, onClick);

  const classNames = ["PrimaryButton"];

  if (disabled) classNames.push("Disabled");

  return (
    <button
      id={id}
      className={classNames.join(" ")}
      onClick={clickHandler}
      style={style}
    >
      {loading ? <Loader inverted active inline size="small" /> : children}
    </button>
  );
};

export const SecondaryButton = ({ onClick, style, children, disabled }) => {
  const classNames = ["SecondaryButton"];

  if (disabled) classNames.push("Disabled");
  return (
    <button
      className={classNames.join(" ")}
      onClick={preventAndFire(onClick)}
      style={style}
    >
      {children}
    </button>
  );
};

export const ToolbarButton = ({
  id,
  icon,
  onClick,
  style,
  background,
  tooltip,
}) => (
  <button
    id={id}
    className="ToolbarButton"
    onClick={onClick}
    style={style}
    title={tooltip}
  >
    <div className="Content" style={{ background }}>
      <img src={icon} alt="btn" />
    </div>
  </button>
);
